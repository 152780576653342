import dayjs from 'dayjs';
import filesize from 'filesize';
import _ from 'lodash';
import currency from 'currency.js';
import $ from 'jquery';

function snakeToCamelCase(obj) {
    // https://github.com/alexnm/json-style-converter
    if (typeof obj === 'string') {
        return snakeToCamel(obj);
    }

    return traverse(obj, snakeToCamel);
}

function camelToSnakeCase(obj, options = {}) {
    // https://github.com/alexnm/json-style-converter
    if (!isSimpleObject(options)) {
        return obj; // avoiding String and other custom objects
    }

    if (typeof obj === 'string') {
        return camelToSnake(obj, options);
    }

    return traverse(obj, camelToSnake, options);
}

function traverse(obj, transform, options) {
    if (!obj) {
        return obj;
    }

    if (typeof obj !== 'object') {
        return obj; // must be an object
    }

    if (isArray(obj)) {
        return obj.map(el => traverse(el, transform, options));
    }

    if (!isSimpleObject(obj)) {
        return obj; // avoiding String and other custom objects
    }

    return Object.keys(obj).reduce((acc, key) => {
        const convertedKey = transform(key, options);
        acc[ convertedKey ] = traverse(obj[ key ], transform, options);
        return acc;
    }, {});
}

function isArray(obj) {
    return Object.prototype.toString.call(obj) === '[object Array]';
}

function isSimpleObject(obj) {
    return Object.prototype.toString.call(obj) === '[object Object]';
}

function snakeToCamel(str) {
    return str.replace(/[_-](\w|$)/g, (match, value) => value.toUpperCase());
}

function camelToSnake(str, { digitsAreUpperCase }) {
    const firstPass = str.replace(/[a-z][A-Z]/g, (letters) => `${letters[0]}_${letters[1].toLowerCase()}`);
    if (digitsAreUpperCase) {
        return firstPass.replace(/[0-9]/g, (digit) => `_${digit}`);
    }

    return firstPass;
}

var token;
function getFetchOptions() {
    var options = {
        cache: 'no-cache',
        mode: 'cors',
        headers: {}
    };
    token = token || getToken();
    if (token) {
        options.headers.Authorization = `Bearer ${token}`;
    }
    return options;
}

function get(_url, data, headers) {
    data = data || {};
    var url = new URL(_url, location.href);
    var searchParams = new URLSearchParams(url.search);
    searchParams.append('_version', Date.now());
    Object.entries(data).forEach(([key, value]) => searchParams.append(key, _.isNil(value) ? '' : value)); 
    url.search = '?' + searchParams;
    var options = Object.assign(getFetchOptions(), {
        method: 'GET'
    });
    Object.assign(options.headers, headers);
    return fetch(url, options).then(done);
}

function post(url, data, type = 'urlencoded', headers) {
    var payload = (type === 'formdata') ? new FormData() : new URLSearchParams();
    Object.entries(data).forEach(([key, value]) => payload.append(key, value)); 
    var options = Object.assign(getFetchOptions(), {
        method: 'POST',
        body: payload
    });
    if (type === 'urlencoded') {
        // Fix IE
        options.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
    }
    Object.assign(options.headers, headers);
    return fetch(url, options).then(done);
}

function postFormData(url, data = {}, headers) {
    return post(url, data, 'formdata', headers);
}

function postJSON(url, data = {}) {
    var options = Object.assign(getFetchOptions(), {
        method: 'POST',
        body: JSON.stringify(data)
    });
    options.headers['Content-Type'] = 'application/json; charset=utf-8';
    return fetch(url, options).then(done);
}

async function done(res) {
    const text = await res.text();
    let json;
    try {
        json = JSON.parse(text); 
    } catch (error) {
        logInfo({
            httpStatus: res.status,
            url: res.url,
            text,
        });
        throw new Error('请求失败，请重试');
    }
    var result = snakeToCamelCase(json);
    if(result.status === 0) {
        return result.data; 
    } else if(requireAuth(result.status)) {
        if(!done.busy) {
            handleError('请重新登录');
            location.href = 'anonymous.html#/login';
            done.busy = true;
        }
    } else {
        throw result;
    }
}

function requireAuth(status) {
    var errorCode = [100, 102, 103, 104, 105, 106, 10650];
    return errorCode.includes(status);
}

function toJSON(data) {
    if(typeof data === 'undefined') {
        return; 
    }
    if(typeof data === 'object') {
        data = JSON.stringify(data);
    }
    return JSON.parse(data);
}

function handleError(error) {
    var msg;
    if(typeof error === 'string') {
        msg = error; 
    } else if(['Failed to fetch', 'cancelled', 'Type error'].includes(error.message)) {
        return; // ignore error
    } else {
        msg = error.message || JSON.stringify(error);
    }
    alert(msg); // eslint-disable-line no-alert
}

function handleError$1(error, message) {
    handleError(getFormatedErrorMessage(error, message));
}

function getFormatedErrorMessage(error, message) {
    const errorStatus = error.status ? `(${error.status})` : '';
    const errorMessage = error.message || '';
    return `${message}${errorStatus}\n${errorMessage}`;
}

function dateFormat(date, template = 'YYYY/MM/DD HH:mm') {
    if(!isNaN(date) && String(date).length <= 10) {
        date *= 1000; // 处理秒时间戳
    }
    return date ? dayjs(date).format(template) : '';
}

function tipAlert(msg) {
    return new Promise(resolve => {
        alert(msg); // eslint-disable-line no-alert
        resolve();
    });
}

function tipConfirm(msg) {
    return new Promise((resolve, reject) => {
        var ret = confirm(msg); // eslint-disable-line no-alert
        ret ? resolve() : reject('cancel');
    });
}

function noop() {}

function maxlength(value, elem, param) {
    // eslint-disable-next-line no-control-regex
    var length = Math.ceil(value.replace(/[^\x01-\xFF]/g, '--').length / 2);
    return length <= param;
}

function blacklist(value, elem, param) {
    // 系统不允许使用下列字符：/\;*?"<>|
    var pattern = /[/\\;*?"<>|]/;
    return !(new RegExp(pattern)).test(value);
}

function truncateMiddle(str, truncateStr) {
    var frontLen = 10;
    var backLen = 8;
    if (str === null) {
        return '';
    }
    var strLen = str.length;
    // Setting default values
    frontLen = ~~frontLen; // will cast to integer
    backLen = ~~backLen;
    truncateStr = truncateStr || '...';
    if (frontLen === 0 && backLen === 0 || frontLen >= strLen || backLen >= strLen || (frontLen + backLen) >= strLen) {
        return str;
    } else if (backLen === 0) {
        return str.slice(0, frontLen) + truncateStr;
    } else {
        return str.slice(0, frontLen) + truncateStr + str.slice(strLen - backLen);
    }
}

function getExtName(title) {
    var index = title.lastIndexOf('.');
    return index >= 0 ? title.slice(index + 1).toLowerCase() : ''; 
}

export const markAType = {
    FOLDER: 2,
    FILE: 1,
    CATALOG: -1,
};

export const suffixMap = {
    2: 'mp4',
    3: 'mov',
    4: 'm4v',
    98: 'mp3',
    99: 'wav',
    101: 'jpg',
    102: 'png',
    103: 'jpeg',
    104: 'gif',
    199: 'svg',
    201: 'pdf',
    202: 'zip',
    203: 'rar',
    204: 'doc',
    205: 'docx',
    206: 'xls',
    207: 'xlsx',
    208: 'csv',
    209: 'ppt',
    210: 'pptx',
    211: 'ai',
    212: 'eps',
    213: 'psd',
    214: 'psb',
    215: 'tif',
    260: 'ttf',
    261: 'ttc',
    262: 'otf',
};

function getCategory(file) {
    if(file.markA === 2) {
        return 'folder';
    }

    if(file.markA === markAType.CATALOG) {
        return 'catalog';
    }

    var format = {
        img: ['png', 'jpg', 'jpeg', 'gif'],
        audio: ['mp3', 'wav'], 
        video: ['mp4', 'mov', 'm4v'],
        doc: ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'csv']
    };
    const suffixText = suffixMap[file.markF];
    for(var key in format) {
        if(format[key].indexOf(suffixText) >= 0) {
            return key;
        }
    }
    return 'other';
}

function getCategoryText(file) {
    var map = {
        img: '图片',
        audio: '音频',
        video: '视频',
        doc: '文档',
        folder: '文件夹',
        catalog: '系统目录',
    };
    var type = getCategory(file);
    return map[type] || '文档'; // 其他类文件归类到文档里
}

function getBasename(file) {
    return file.title;
}

// https://github.com/feross/load-script2
function loadScript(src, cb) {
    var head = document.head || document.getElementsByTagName('head')[0];
    var script = document.createElement('script');

    script.type = 'text/javascript';
    script.async = true;
    script.src = src;

    if(cb) {
        script.onload = function () {
            script.onerror = script.onload = null;
            cb(null, script);
        };
        script.onerror = function () {
            script.onerror = script.onload = null;
            cb(new Error('Failed to load ' + src), script);
        };
    }

    head.appendChild(script);
}

function parseMs(ms) {
    if (typeof ms !== 'number') {
        throw new TypeError('Expected a number');
    }
    var roundTowardsZero = ms > 0 ? Math.floor : Math.ceil;

    return {
        days: roundTowardsZero(ms / 86400000),
        hours: roundTowardsZero(ms / 3600000) % 24,
        minutes: roundTowardsZero(ms / 60000) % 60,
        seconds: roundTowardsZero(ms / 1000) % 60,
        milliseconds: roundTowardsZero(ms) % 1000,
        microseconds: roundTowardsZero(ms * 1000) % 1000,
        nanoseconds: roundTowardsZero(ms * 1e6) % 1000
    };
}

function parseDuration(ms) {
    var list = [
        {
            key: 'days',
            unit: '天',
        },
        {
            key: 'hours',
            unit: '小时',
        },
        {
            key: 'minutes',
            unit: '分',
        },
        {
            key: 'seconds',
            unit: '秒',
        },
    ];
    var result = [];
    var obj = parseMs(ms);
    list.forEach(item => {
        var value = obj[item.key];
        value && result.push(value + item.unit);
    });
    return result.join('');
}

function equalto(value, elem, param) {
    return value === param;
}

function mobile(value, elem, param) {
    return /^1\d{10}$/.test(value);
}

function strongpassword(value, elem, param) {
    var number = /[0-9]/;
    var lowerCase = /[a-z]/;
    var upperCase = /[A-Z]/;
    return number.test(value) && lowerCase.test(value) && upperCase.test(value);
}

function getToken() {
    var token = sessionStorage.getItem('token') || localStorage.getItem('token');
    if(token) {
        return token;
    }
    var hash = location.hash;
    var query = hash.slice(hash.indexOf('?'));
    var params = new URLSearchParams(query);
    return params.get('token');
}

function logInfo(params) {
    var searchParams = new URLSearchParams();
    Object.entries(params).forEach(([key, value]) => searchParams.append(key, value));
    const img = new Image();
    img.src = '/data.gif?' + searchParams;
}

function fixJSONKeys(obj) {
    if (!_.isPlainObject(obj)) {
        return obj;
    }
    const keys = [
        // 通用字段
        'ext',

        // contents表
        'content',
        'top',

        // options表
        'value',

        // user表
        'erp',
        'privateInfo',
    ];
    const entries = Object.entries(obj);
    for (const [key, value] of entries) {
        // 后端接口返回的json数据是string类型，需要前端手动转化
        // 目前转化类型为string, array, object
        if (keys.includes(key) && value && /[{["]/.test(value.toString()[0])) {
            try {
                obj[key] = JSON.parse(value.toString());
            } catch (error) {
                // ignore error
            }
        }
    }
    return obj;
}

function numberFormat(value, precision) {
    const precision$1 = precision || 2;
    return currency(value, {
        symbol: '',
        separator: ',',
        decimal: '.',
        precision: precision$1,
    }).format().replace(/(\.0+|0)$/g, ''); // 去掉小数点后多余的0
}

function domMatches(dom, selector) {
    if (!dom) {
        return;
    }
    if (typeof dom.matches === 'function') {
        return dom.matches(selector);
    }
    return $(selector, dom).length > 0;
}

function downloadFile(fileIdOrPath, filename) {
    const id = '';
    const absoluteFilePath = /^\d+$/.test(fileIdOrPath) ? ('/api2/apiUser/userReadFile/download/' + fileIdOrPath) : fileIdOrPath;
    const filePath = 'https://cica.goldwind.com' + absoluteFilePath;
    const lastModified = '';
    const cookie = document.cookie;
    try {
        if (window.android) {
            window.android.downloadFile(id, filePath, filename, lastModified, cookie);
            return;
        }
        if (window.webkit) {
            const params = {id, filePath, filename, lastModified, cookie};
            window.webkit.messageHandlers.downloadFile.postMessage(params);
            return;
        }
    } catch (_error) {
        // ignore error
    }
    const link = document.createElement('a');
    link.href = filePath;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
}

function isWeChatBrowser() {
    var ua = navigator.userAgent.toLowerCase();
    return ua.indexOf('micromessenger') !== -1;
}

function isMobileBrowser() {
    var ua = navigator.userAgent.toLowerCase();
    return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini|windows phone/i.test(ua);
}

export {
    snakeToCamelCase as toCamel,
    camelToSnakeCase as toSnake,
    get,
    post,
    postFormData,
    postJSON,
    toJSON,
    handleError,
    handleError$1,
    getFormatedErrorMessage,
    dateFormat,
    tipAlert,
    tipConfirm,
    noop,
    maxlength,
    blacklist,
    truncateMiddle,
    getExtName,
    getCategory,
    getCategoryText,
    getBasename,
    filesize as sizeFormat,
    loadScript,
    parseDuration,
    equalto,
    mobile,
    strongpassword,
    getToken,
    logInfo,
    fixJSONKeys,
    numberFormat,
    domMatches,
    downloadFile,
    isWeChatBrowser,
    isMobileBrowser,
};
