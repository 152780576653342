import isPrivateIp from 'private-ip';
import _ from 'lodash';
import to from 'await-to-js';
import {
    get,
    getCategory,
} from './utils.js';

var devHosts = [
    'localhost',
    'cica.localhost.com',
];
var hostname = location.hostname;
var isDev = devHosts.includes(hostname) || isPrivateIp(hostname); 
var env;
if(isDev) {
    env = 'development';
} else if('cica.gthon.com' === hostname) {
    env = 'qa';
} else {
    env = 'production';
}

var urlMap = {};

urlMap.development = {
    login: 'mock/login-success.json',
    'provider/login': 'mock/login.json',
    'wechat-api/login': 'mock/wechat/pc.json',
    'login-with-sign': 'mock/login-success.json',
    permissions: 'mock/permissions.json',
    user: 'mock/user.json',
    nav: 'mock/nav.json',
    uploadTarget: 'mock/upload.json',
    'folder/new': 'mock/success.json',
    files: 'mock/files.json',
    rename: 'mock/success.json',
    remove: 'mock/success.json',
    move: 'mock/success.json',
    path: 'mock/path.json',
    download: '../mock/file.txt',
    pack: 'mock/pack.json',
    'pack-status': 'mock/success.json',
    convert: 'mock/convert.json',
    preview: '../images/_temp/goldwind.jpg?',
    'metadata/view': 'mock/metadata.json',
    'metadata/save': 'mock/success.json',
    search: 'mock/search.json',
    postmedia: 'mock/upload.json',
    share: 'mock/success.json',
    'provider/add': 'mock/success.json',
    'provider/password': 'mock/success.json',
    upload: 'mock/provider/upload.json',
    'resumable-upload': 'mock/resumable-upload.json',
    track: 'mock/track.gif',
    news: 'mock/news/list.json',
    'cms/all-terms': 'mock/terms.json',
    'cms/one-article': 'mock/cms/one-article.json',
    'cms/get-ugc': 'mock/ugc-clues.json',
    'cms/update-ugc': 'mock/success.json',
    'cms/add-ugc@v3': 'mock/success.json',
    'cms/update-ugc@v3': 'mock/success.json',
    'cms/remove-my-ugc': 'mock/success.json',
    'users': 'mock/users.json',
    'cica-data/contents': 'mock/cica-data/contents.json',
    'cica-data/reports': 'mock/cica-data/reports.json',
    'cica-data/ugc': 'mock/cica-data/ugc.json',
    'cica-data/one-ugc': 'mock/cica-data/one-ugc.json',
    'cica-data/add-maintain-ugc': 'mock/add-success.json',
    'cica-data/stop-maintain-ugc': 'mock/success.json',
    'cica-data/add-story-ugc': 'mock/add-success.json',
    'bdc-file/html2word': 'mock/html2word.json',
    'bdc-file/download-word': 'mock/report.docx',
    'bdc-file/html2pdf': 'mock/html2pdf.json',
    'bdc-file/download-pdf': 'mock/report.pdf',
    'send-mail': 'mock/success.json',
    'cms/content/search': 'mock/contents.json',
    'cms/content/list@v2': 'mock/contents.json',
    'cms/content/nodejs-get': 'mock/contents.json',
    'bdc-files': 'mock/bdc-files.json',
    'cms-files': 'mock/cms-files.json',
    'preview-office': 'mock/office.pdf?',
};

var urlRoot = '/api/cica/cica-manage';
urlMap.production = {
    login: '/api2/login/staffLogin',
    'wechat-api/login': getWechatPublicPath() + '/wechat-api/login',
    'login-with-sign': '/api2/login/noPasswordLogin',
    permissions: '/api2/cms/getUserPermission',
    user: '/api2/cms/getUserInfo',
    nav: urlRoot + '/query/catalog',
    uploadTarget: urlRoot + '/upload/file',
    'folder/new': urlRoot + '/new/folder',
    files: urlRoot + '/find/all/files',
    rename: urlRoot + '/rename/file',
    remove: urlRoot + '/delete/file',
    move: urlRoot + '/move/files',
    path: urlRoot + '/get/file/path',
    download: urlRoot + '/download/file/',
    pack: '/api2/apiUser/userDownloadZip',
    'pack-status': urlRoot + '/ws',
    convert: urlRoot + '/file/convert/state',
    preview: urlRoot + '/preview/file/',
    'metadata/view': urlRoot + '/preview/file/info',
    'metadata/save': urlRoot + '/save/file/info',
    search: urlRoot + '/search/file',
    postmedia: urlRoot + '/upload/file/ext',
    share: urlRoot + '/share/files',
    'provider/add': '/api2/supplier/add',
    'provider/password': '/api2/supplier/resetPassword',
    upload: '/api2/file/fileUpload',
    'resumable-upload': '/api2/bdcFile/upload',
    track: '/api2/log/record',
    news: 'energy-api/news/feeds/',
    'cms/all-terms': '/api2/cmsTerm/getAllTerms',
    'cms/one-article': '/api2/cmsContent/getContentById',
    'cms/get-ugc': '/nodejs-api/search-ugc',
    'cms/update-ugc': '/api2/cmsUgc/updateCmsUgc',
    'cms/add-ugc@v3': '/api2/cmsUgc/v3/addCmsUgc',
    'cms/update-ugc@v3': '/api2/cmsUgc/v3/updateCmsUgc',
    'cms/remove-my-ugc': '/api2/cmsUgc/v2/deleteCmsUgc',
    'users': '/api2/user/getUserInfo',
    'cica-data/contents': '/api2/cmsContent/readContentList',
    'cica-data/reports': '/api2/cmsContent/readContentList',
    'cica-data/ugc': '/api2/cmsUgc/v2/getCmsUgc',
    'cica-data/one-ugc': '/api2/cmsUgc/getById',
    // 'cica-data/add-maintain-ugc': '/api2/cmsUgc/v2/addCmsUgc',
    'cica-data/stop-maintain-ugc': '/api2/cmsUgc/v2/cmsUgcDataEnd',
    'cica-data/add-story-ugc': '/api2/cmsUgc/v2/addCmsUgcDataStory',
    'bdc-file/html2word': '/api2/bdcFile/htmlToWord',
    'bdc-file/download-word': '/api2/bdcFile/getDocFile',
    'bdc-file/html2pdf': '/nodejs-api/html2pdf',
    'bdc-file/download-pdf': '/nodejs-api/download-pdf',
    'send-mail': '/api2/dataResource/sendMail',
    'cms/content/search': '/api2/cmsContent/searchContents',
    'cms/content/list@v2': '/api2/cmsContent/readContentList',
    'cms/content/nodejs-get': '/nodejs-api/cms_contents/filter',
    'bdc-files': '/api2/bdcFile/getFiles',
    'cms-files': '/api2/apiUser/userGetFiles', 
    'preview-office': '/api2/apiUser/userViewOffice/'
};

urlMap.qa = Object.assign({}, urlMap.production);

var myUrlMap = urlMap[env];

const sitePublicPathMap = {
    development: 'http://localhost/cica/dist/',
    qa: 'http://cica.gthon.com',
    production: 'https://cica.goldwind.com',
};
const sitePublicPath = sitePublicPathMap[env] || 'https://cica.goldwind.com';

const PUBLIC_MEDIA_NAV_ID = 'b4nXR3poV6G7d8Ly'; // 生产环境“宣传视频”栏目下，全员可下载原片

export function getWechatPublicPath() {
    var map = {
        qa: 'http://05271020gw.gthon.com/',
        production: 'https://www.goldwind.com',
    };
    return map[env] || map['production'];
}

export const cicaFileSysParent = '920341604714369045';

function getFileRoutePath(context, file) {
    var root = context.$root;
    return {
        name: context.route.name,
        params: {
            nav: root.navId,
            menu: root.menuId
        },
        query: {
            folderId: file.id
        }
    };
}

var myUrlRoot = (env === 'development') ? '' : urlRoot;

function getThumbnail(file) {
   return file.varcharA || file.varcharB; 
}

export function getPreviewFile(file) {
    if (getCategory(file) === 'img') {
        return '/api2/apiUser/userReadFile/view/' + (file.relationA || file.contentBigint);
    }
    return '/api2/apiUser/userReadFile/view/' + file.contentBigint;
}

function isCorpUser(email) {
    var whiteList = [
        '@goldwind.com.cn',
        '@goldwindinternational.com',
        '@goldwindamericas.com',
        '@goldwindaustralia.com',
        '@goldwindgermany.com',
        '@goldwinddenmark.com',
        '@tianrun.cn',
        '@etechwin.com.cn',
        '@bj-wind.com',
        '@vensys.de',
    ];
    return !!whiteList.find(item => email.endsWith(item));
}

async function getFiles(ids) {
    const ids$1 = _.uniq(ids.filter(item => !_.isEmpty(item)));
    if (ids$1.length <= 0) {
        return {};
    }
    const [error, data] = await to(get(myUrlMap['bdc-files'], { ids: ids$1.join(',') }));
    if (error) {
        return {};
    }
    return _.keyBy(data, 'id');
}

export async function getCmsFiles(ids) {
    const ids$1 = _.uniq(ids.filter(item => !_.isEmpty(item)));
    if (ids$1.length <= 0) {
        return {};
    }
    const [error, data] = await to(get(myUrlMap['cms-files'], { ids: ids$1.join(',') }));
    if (error) {
        return {};
    }
    return _.keyBy(data.cmsFiles, 'id');
}

function getFileFullDist(file) {
    const websitePublicPath = getGoldwindSitePublicPath();
    return websitePublicPath + file.dist;
}

function getGoldwindSitePublicPath() { // 前台网站
    var map = {
        development: 'http://localhost/cica/dist/',
        qa: 'http://goldwind.gw.gthon.com',
        production: 'https://www.goldwind.com',
    };
    return map[env];
}

export {
    env,
    myUrlRoot as urlRoot,
    myUrlMap as urlMap,
    sitePublicPath,
    PUBLIC_MEDIA_NAV_ID,
    getFileRoutePath,
    getThumbnail,
    isCorpUser,
    getFiles,
    getFileFullDist,
};
